import { Typography, Container, IconButton, Tooltip, Link, Grid2, useMediaQuery } from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Logo from './Logo.png';
import background from './background.png'

const Error404 = () => {
    const isMob = useMediaQuery('(max-width:1000px)');

    document.body.style = 'background: #111213';

    return (
        <div className='bg' style={{backgroundImage: `url(${background})`}}>
            <Container maxWidth="xl">
                {isMob ? 
                    <Grid2 container sx={{pt:3}} direction='column' alignItems={'flex-start'}>

                    <img alt={"logo"} src={Logo} style={{width:'40%', marginBottom:5}}></img>

                    <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, fontSize:36.5}} href='https://store.steampowered.com/app/721990/Dead_Spawn/'>Purchase</Link>
                    <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, fontSize:36.5}} href='https://www.yzeegames.com/dead-spawn-press-kit/'>Press Kit</Link>
                    <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, fontSize:36.5}} href='https://www.yzeegames.com/contact/'>Contact</Link>

                    <Grid2>
                        <Tooltip title="Twitter"><IconButton href="https://x.com/DeadSpawnGame" sx={{ "&:hover": {color: "#df3c1d"}, color:'white'}}><TwitterIcon sx={{fontSize:25}}/></IconButton></Tooltip>
                        <Tooltip title="YouTube"><IconButton href="https://www.youtube.com/c/YZeeGames" sx={{ "&:hover": {color: "#df3c1d"}, color:'white'}}><YouTubeIcon sx={{fontSize:25}}/></IconButton></Tooltip>
                    </Grid2>
                    </Grid2> 
                    : <Grid2 container sx={{pt:3}} alignItems={'center'} justifyContent={'space-between'}>
                        <Grid2 size={10} item container alignItems={'center'} justifyContent={'flex-start'}>

                            <img alt={"logo"} src={Logo} style={{marginRight:50}}></img>
                            
                            <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, mr:6, fontSize:46.5}} href='https://store.steampowered.com/app/721990/Dead_Spawn/'>Purchase</Link>
                            <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, mr:6, fontSize:46.5}} href='https://www.yzeegames.com/dead-spawn-press-kit/'>Press Kit</Link>
                            <Link fontFamily={'Bahiana'} sx={{textDecoration: 'none', color:'white', "&:hover": {color: "#df3c1d"}, fontSize:46.5}} href='https://www.yzeegames.com/contact/'>Contact</Link>

                        </Grid2>
                        <Grid2 size={2} item container alignItems={'center'} justifyContent={'flex-end'}>

                            <Tooltip title="Twitter"><IconButton href="https://x.com/DeadSpawnGame" sx={{ "&:hover": {color: "#df3c1d"}, color:'white'}}><TwitterIcon sx={{fontSize:35}}/></IconButton></Tooltip>
                            <Tooltip title="YouTube"><IconButton href="https://www.youtube.com/c/YZeeGames" sx={{ "&:hover": {color: "#df3c1d"}, color:'white'}}><YouTubeIcon sx={{fontSize:35}}/></IconButton></Tooltip>

                        </Grid2>
                    </Grid2>
                }

                <Container component="main" maxWidth="sm" align='center' sx={{mb:15, mt:isMob ? 10 : 15}}>
                    <Typography sx={{fontSize: isMob ? 60 : 80, lineHeight:1, color:'white'}} fontFamily={'Bahiana'}>ERROR 404</Typography>
                    <Typography sx={{fontSize: isMob ? 30 : 40, color:'white'}} fontFamily={'Bahiana'}>I'm afraid this link does not exist !</Typography>
                    <SmartToyIcon sx={{ fontSize: isMob ? 60 : 80, color:'white' }}/>
                </Container>

            </Container>

            <Container
                maxWidth={false}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: 'white',
                    paddingTop: 3,
                    paddingBottom: 3,
                }}
            >       
                <Grid2 container direction="column" alignItems="center">
                    <Typography fontFamily="Bahiana" sx={{ fontSize: 24 }} color="black">
                    <strong>YZeeGames</strong> © 2016. All Rights Reserved
                    </Typography>
                    <Grid2 container sx={{ mt: 1.5 }} direction="row" justifyContent="center">
                    <Link fontFamily="Bahiana" sx={{ textDecoration: 'none', color: '#df3c1d', fontSize: 24 }} href="https://store.steampowered.com/app/721990/Dead_Spawn/">Steam</Link>
                    <Typography fontFamily="Bahiana" sx={{ fontSize: 24, mx: 1 }} color="#df3c1d">|</Typography>
                    <Link fontFamily="Bahiana" sx={{ textDecoration: 'none', color: '#df3c1d', fontSize: 24 }} href="https://www.yzeegames.com/">Main Site</Link>
                    <Typography fontFamily="Bahiana" sx={{ fontSize: 24, mx: 1 }} color="#df3c1d">|</Typography>
                    <Link fontFamily="Bahiana" sx={{ textDecoration: 'none', color: '#df3c1d', fontSize: 24 }} href="https://www.yzeegames.com/dead-spawn-press-kit/">Press Kit</Link>
                    <Typography fontFamily="Bahiana" sx={{ fontSize: 24, mx: 1 }} color="#df3c1d">|</Typography>
                    <Link fontFamily="Bahiana" sx={{ textDecoration: 'none', color: '#df3c1d', fontSize: 24 }} href="https://www.yzeegames.com/press-kit/">YZee Games Press Kit</Link>
                    <Typography fontFamily="Bahiana" sx={{ fontSize: 24, mx: 1 }} color="#df3c1d">|</Typography>
                    <Link fontFamily="Bahiana" sx={{ textDecoration: 'none', color: '#df3c1d', fontSize: 24 }} href="https://www.yzeegames.com/contact/">Contact</Link>
                    </Grid2>
                </Grid2>
            </Container>

        </div>
    )
}

export default Error404;